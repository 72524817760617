<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
              <CInput
                :placeholder="$t('search_a') + $t('name')"
                v-model="searchData.name"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getPhoneBooks()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createPhoneBookModal = { modal: true }">{{ $t('create_phone_book') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="devices" slot-scope="props">
              {{ $_.pluck(props.row.devices, 'name').join(', ') }}
            </font>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editPhoneBookModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deletePhoneBookModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreatePhoneBookModal :data="createPhoneBookModal.data" :show="createPhoneBookModal.modal" @showUpdate="show => createPhoneBookModal.modal = show" @formSubmit="getPhoneBooks()" />

      <EditPhoneBookModal :data="editPhoneBookModal.data" :show="editPhoneBookModal.modal" @showUpdate="show => editPhoneBookModal.modal = show" @formSubmit="getPhoneBooks()" />

      <DeletePhoneBookModal :data="deletePhoneBookModal.data" :show="deletePhoneBookModal.modal" @showUpdate="show => deletePhoneBookModal.modal = show" @formSubmit="getPhoneBooks()" />

    </CCol>
  </CRow>
</template>

<script>
import CreatePhoneBookModal from './modal/CreatePhoneBookModal'
import EditPhoneBookModal from './modal/EditPhoneBookModal'
import DeletePhoneBookModal from './modal/DeletePhoneBookModal'

export default {
  name: 'phoneBooks',
  components: {
    CreatePhoneBookModal,
    EditPhoneBookModal,
    DeletePhoneBookModal,
  },
  data () {
    return {
      columns: [ 'admin_name', 'name', 'devices', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          admin_name: this.$t('account_name'),
          name: this.$t('name'),
          devices: this.$t('devices'),
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'admin_name', 'name', 'devices', 'created_at', 'enabled' ],
        filterable: [ 'admin_name', 'name', 'devices', 'created_at', 'enabled' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { name: '' },
      createPhoneBookModal: { data: {}, modal: false },
      editPhoneBookModal: { data: {}, modal: false },
      deletePhoneBookModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getPhoneBooks()
  },
  methods: {
    getPhoneBooks () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getPhoneBooks', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
